import { render, staticRenderFns } from "./OkModal.vue?vue&type=template&id=3f0a7fed&scoped=true"
import script from "./OkModal.vue?vue&type=script&lang=ts"
export * from "./OkModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../imicsspa/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0a7fed",
  null
  
)

export default component.exports